import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import logo from './Logo FDS.png'
import './styles.css'
import React, { useState } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

function Header() {
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <div className="header-container">
      <header>
        <div className="title-container">
          <Link to="/"> 
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <h1 className="title">
            {['F', 'D', 'S', ' ', 'c', 'o', 'n', 's', 'u', 'l', 't', 'i', 'n', 'g'].map((letter, index) => (
              <motion.span
                key={index}
                initial="hidden"
                animate="visible"
                variants={titleVariants}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {letter}
              </motion.span>
            ))}
          </h1>
        </div>
        <nav className="justify-content-center">
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/services">Nos Services</Link></li>
            <li><Link to="/team">Notre Équipe</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>
    </div>
  )
}

function Home() {
  return (
    <div>
      <section id="hero">
        <div className="container">
          <div className="hero-content">
            <h2>Bienvenue chez FDS Consulting</h2>
            <p>Nous offrons des services de consultation pour aider votre entreprise à réussir.</p>
            <p>3 RUE DES LIGNES 59100 ROUBAIX NORD France</p>
            <Link to="/contact" className="btn">Contactez-nous</Link>
          </div>
        </div>
      </section>
      <div>
    
    </div>
    </div>
  )
}

function Services() {
  return (
    <div className="container">
      <h2>Nos Services</h2>
      <div className="background-image-services">
        <img src="Mano.jpg" alt="Background" className="background-image" />
        <div className="services-container">
          <div className="service-item">
            <img src="courbe.png" alt="Service 1" className="service-icon" />
            <h3>Consultation en Stratégie d'Entreprise</h3>
            <p>Notre équipe d'experts vous accompagne dans l'élaboration d'une stratégie d'entreprise solide et efficace, adaptée à vos objectifs et à votre secteur d'activité.</p>
          </div>
          <div className="service-item">
            <img src="market1.png" alt="Service 2" className="service-icon" />
            <h3>Controle de gestion</h3>
            <p>Notre équipe de professionnels qualifiés s'engage à fournir des solutions sur mesure pour répondre à vos besoins spécifiques en matière de contrôle de gestion. Grâce à une analyse approfondie de vos processus et de vos données financières, nous identifions les opportunités d'optimisation et les zones de risque potentiel.</p>
          </div>
          <div className="service-item">
            <img src="projet2.png" alt="Service 3" className="service-icon" />
            <h3>Gestion de Projet Personnalisée</h3>
            <p>Nous vous proposons une gestion de projet sur mesure, en fournissant des solutions adaptées à vos besoins spécifiques, pour garantir la réussite de vos initiatives.</p>
          </div>
          <div className="service-item">
            <img src="projet.png" alt="Service 4" className="service-icon" />
            <h3>Formation et Développement Professionnel</h3>
            <p>Nous proposons des programmes de formation personnalisés pour développer les compétences de vos équipes et renforcer leur expertise dans différents domaines professionnels.</p>
          </div>
        </div>
      </div>
    </div>
  );
}



function Team() {
  return (
    <div className="container">
      <h2>Notre Équipe</h2>
      <div className="team-grid">
        <div className="team-member-wrapper">
          <div className="team-member">
            <div className="team-member-image">
              <img src="Photomember.jpg" alt="Membre de l'équipe 1" />
            </div>
            <div className="team-member-details">
              <h3>Nom du Membre 1</h3>
              <p>Rôle / Spécialisation</p>
            </div>
          </div>
        </div>
        <div className="team-member-wrapper">
          <div className="team-member">
            <div className="team-member-image">
              <img src="Photomember.jpg" alt="Membre de l'équipe 2" />
            </div>
            <div className="team-member-details">
              <h3>Nom du Membre 2</h3>
              <p>Rôle / Spécialisation</p>
            </div>
          </div>
        </div>
      </div>
      <p className="team-description">Notre equipe est là pour vous accompagner à chaque étape de votre parcours. Que ce soit pour développer la stratégie de votre entreprise, mettre en œuvre des campagnes de marketing digital percutantes, gérer des projets complexes ou encore former votre personnel, nous mettons à votre disposition notre expertise et notre engagement pour atteindre vos objectifs. Avec une approche personnalisée et une attention aux détails, nous travaillons en étroite collaboration avec vous pour garantir la réussite de vos projets et la croissance de votre entreprise.</p>
      
    </div>

  );
}


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        console.log('E-mail envoyé avec succès');
        // Afficher un message de succès à l'utilisateur si nécessaire
      } else {
        console.error('Erreur lors de l\'envoi de l\'e-mail:', response.statusText);
        // Afficher un message d'erreur à l'utilisateur si nécessaire
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'e-mail:', error.message);
      // Afficher un message d'erreur à l'utilisateur si nécessaire
    }
  };

  return (
    <div>
      <h1 class="contact-title">Contactez-nous</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Nom</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Adresse e-mail</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <button type="submit" className="btn">
          Envoyer
        </button>
      </form>
      <div className="map-container">
        <LoadScript googleMapsApiKey="AIzaSyBLnqxPMaC7pT3kd1xX1ue1H34nXlaBIUQ"> 
          <GoogleMap
            mapContainerStyle={{ height: "300px", width: "25%" }}
            center={{ lat: 50.693818, lng: 3.170329 }} // Coordonnées du centre 
            zoom={19} 
          >
            <Marker position={{ lat: 50.693818, lng: 3.170329 }} /> 
          </GoogleMap>
        </LoadScript>
        <div className="contact-info">
          <h3>Informations de contact</h3>
          <ul>
            <li>Adresse: 3 RUE DES LIGNES 59100 ROUBAIX NORD France</li>
            <li>Téléphone: +33 6 11 66 24 00</li>
            <li>Email: contact@fdsconsulting.fr</li>
          </ul>
        </div>
      </div>
    </div>
  );
}


function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <div>
      <footer>
        <p>&copy; {new Date().getFullYear()} FDS Consulting | Tous droits réservés.</p>
      </footer>
      </div>
    </Router>
  )
}

export default App;